import {useState, useEffect} from "react";
import styled, { keyframes } from "styled-components";
import ContentService from '../services/content.service'
import configData from "../config.json";
const IMAGE_URL = configData.REACT_APP_IMAGE_URL;
const queryParams = new URLSearchParams(window.location.search);
const content = queryParams.get("content");
const pid = queryParams.get("pid");
function Hasil() {
    const [dataContent, setDataContent] = useState([])
    
    const getContent = async () => {
        try {
            const {data: _dataContent} = await ContentService.getContent(content,pid);
            
            setDataContent(_dataContent);
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        getContent()
    }, []);
    var showIDs = [];
    
    const showProducts = (hoverID) => {
        while(showIDs.length > 0) document.getElementById(showIDs.pop()).style.display = "none";

        // document.getElementById('hover').classList.add("hover-show");
        document.getElementById('hover').style.transitionDelay = "0s";
        document.getElementById('hover').style.visibility = "visible";
        document.getElementById('hover').style.opacity = "1";
        document.getElementById(hoverID).style.display = "flex";

        showIDs.push(hoverID);
    }
    const hideProducts = (hoverID) => {
        document.getElementById('hover').style.visibility = "hidden";
        document.getElementById('hover').style.opacity = "0";
        document.getElementById('hover').style.transitionDelay = "0.5s";
    }

    const movingText = keyframes`
    0%{
    transform:translateX(80%);
    }
    100%{
    transform:translateX(-80%);
    }
    `;

    const Marquee = styled.span`
    @media (prefers-reduced-motion: no-preference) {
        animation: ${movingText} 15s linear infinite;
        :hover {
        animation-play-state: paused;
        }
    }
    cursor: default;
    `;
return (
    <>
         <div id="hover" className="hover desktop-only">

            <div id="hover-togel" className="hover-menu">
                <a href="https://birutoto.nl/?content=register" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//icon-togel1.png" alt="PAY4D" /></figure>
                    <figcaption>TOGEL</figcaption>
                </a>
            </div>

            <div id="hover-slot" className="hover-menu">
                <a href="https://birutoto.nl/?content=slot&provider=pp" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-prag.png" alt="PRAGMATIC PLAY" /></figure>
                    <figcaption>PRAGMATIC PLAY</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=pg" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-pg.png" alt="PG SOFT" /></figure>
                    <figcaption>PG SOFT</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=hb" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-hab.png" alt="HABANERO" /></figure>
                    <figcaption>HABANERO</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=sg" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-spad.png" alt="SPADEGAMING" /></figure>
                    <figcaption>SPADEGAMING</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=jg" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-jok.png" alt="JOKER" /></figure>
                    <figcaption>JOKER</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=nlc" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-nlc.png" alt="NOLIMIT CITY" /></figure>
                    <figcaption>NOLIMIT CITY</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=jl" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-jl.png" alt="JILI" /></figure>
                    <figcaption>JILI</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=mg" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-mg.png" alt="MICROGAMING" /></figure>
                    <figcaption>MICRO GAMING</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=ps" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-ps.png" alt="PLAYSTAR" /></figure>
                    <figcaption>PLAYSTAR</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=fs" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-fs.png" alt="FASTSPIN" /></figure>
                    <figcaption>FASTSPIN</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=nte" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-nte.png" alt="NETENT" /></figure>
                    <figcaption>NETENT</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=l22" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-l22.png" alt="LIVE22" /></figure>
                    <figcaption>LIVE22</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=cq9" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-cq9.png" alt="CQ9" /></figure>
                    <figcaption>CQ9</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=ttg" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-ttg.png" alt="TOPTREND GAMING" /></figure>
                    <figcaption>TOPTREND GAMING</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=slot&provider=ss" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//slot-sbo.png" alt="SBO SLOT" /></figure>
                    <figcaption>SBO SLOT</figcaption>
                </a>
            </div>

            <div id="hover-live" className="hover-menu" >
                <a href="https://birutoto.nl/?content=casino&provider=pplc" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//live-pp.png" alt="PRAGMATIC PLAY" /></figure>
                    <figcaption>PRAGMATIC PLAY</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=casino&provider=ion" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//live-ion.png" alt="ION CASINO" /></figure>
                    <figcaption>ION CASINO</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=casino&provider=sx" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//live-sg.png" alt="SEXY GAMING" /></figure>
                    <figcaption>SEXY GAMING</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=casino&provider=evo" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//live-evo.png" alt="EVOLUTION" /></figure>
                    <figcaption>EVOLUTION</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=casino&provider=sa" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//live-sagaming.png" alt="SA GAMING" /></figure>
                    <figcaption>SA GAMING</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=casino&provider=ab" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//live-all.png" alt="ALLBET" /></figure>
                    <figcaption>ALLBET</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=casino&provider=mg" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//live-mg.png" alt="MICRO GAMING" /></figure>
                    <figcaption>MICRO GAMING</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=casino&provider=og" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//live-opus.png" alt="OPUS PLUS" /></figure>
                    <figcaption>OPUS PLUS</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=casino&provider=sbol" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//live-sbo.png" alt="SBOBET CASINO" /></figure>
                    <figcaption>SBOBET CASINO</figcaption>
                </a>
            </div>

            <div id="hover-sport" className="hover-menu">
                <a href="https://birutoto.nl/?content=register" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//sport-saba.png" alt="SABA" /></figure>
                    <figcaption>SABA</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=register" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//sport-sbo.png" alt="SBOBET" /></figure>
                    <figcaption>SBOBET</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=register" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//sport-cmd.png" alt="CMD368" /></figure>
                    <figcaption>CMD368</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=register" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//sport-tf.png" alt="TFGAMING" /></figure>
                    <figcaption>TFGAMING</figcaption>
                </a>
            </div>

            <div id="hover-fishing" className="hover-menu">
                <a href="https://birutoto.nl/?content=fish&provider=sg" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//fish-sg.png" alt="SPADE GAMING" /></figure>
                    <figcaption>SPADE GAMING</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=fish&provider=jl" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//fish-jl.png" alt="JILI" /></figure>
                    <figcaption>JILI</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=fish&provider=ps" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//fish-ps.png" alt="PLAYSTAR" /></figure>
                    <figcaption>PLAYSTAR</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=fish&provider=fs" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//fish-fs.png" alt="FASTSPIN" /></figure>
                    <figcaption>FASTSPIN</figcaption>
                </a>

                <a href="https://birutoto.nl/?content=fish&provider=l22" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//fish-l22.png" alt="LIVE22" /></figure>
                    <figcaption>LIVE22</figcaption>
                </a>

            </div>

            <div id="hover-sabung" className="hover-menu">
                <a href="https://birutoto.nl/?content=sabung&provider=ws" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//sabung-ws.png" alt="WS168" /></figure>
                    <figcaption>WS168</figcaption>
                </a>
            </div>

            <div id="hover-interactive" className="hover-menu">
                <a href="https://birutoto.nl/?content=interactive&provider=gmn" className="hover-item">
                    <figure><img src="https://img.viva88athenae.com//interactive-gmn.png" alt="GEMINI" /></figure>
                    <figcaption>GEMINI</figcaption>
                </a>
            </div>
        </div>
        <div className="mobile-only" >
            <div className="my-navbar">
                <a href="https://birutoto.nl/" className="navbar-item" style={{position:"relative"}}>
                    <div className="navbar-item-content">
                        <img src="https://img.viva88athenae.com//beranda.png" alt="" />
                        <label>BERANDA</label>
                    </div>
                </a>
                <div style={{height:"36px",width:"3px",background:"black",margin:"auto"}} ></div>
                <a href="https://birutoto.nl/m/promosi.php"  className="navbar-item">
                    <div className="navbar-item-content">
                        <img src="https://img.viva88athenae.com//promosi.png" alt=""/>
                        <label>PROMOSI</label>
                    </div>
                </a>
                <div style={{height:"36px",width:"3px",background:"black",margin:"auto"}} ></div>
                <a href="https://birutoto.nl/m/event.php"  className="navbar-item">
                    <div className="navbar-item-content">
                        <img src="https://img.viva88athenae.com//events.png" alt=""/>
                        <label>EVENT</label>
                    </div>
                </a>

                <div style={{height:"36px",width:"3px",background:"black",margin:"auto"}} ></div>
                <a href="https://shrt999.xyz/wabiru"  target="_blank" className="navbar-item">
                    <div className="navbar-item-content">
                        <img src="https://img.viva88athenae.com//whatsapp.png" alt=""/>
                        <label>WHATSAPP</label>
                    </div>
                </a>

                <div style={{height:"36px",width:"3px",background:"black",margin:"auto"}} ></div>
                <a href="https://secure.livechatenterprise.com/customer/action/open_chat?license_id=14108205"  target="_blank" className="navbar-item">
                    <div className="navbar-item-content">
                        <img src="https://img.viva88athenae.com//livechat.png" alt=""/>
                        <label>LIVECHAT</label>
                    </div>
                </a>

            </div>
        </div>
        <div id="top" className="wrapper">
            <header className="header">
                <div className="header-content">
                    <div className="logo" href="/">
                        <a href='/'><img src={IMAGE_URL+"okayidr-logo.png"}   className='logoimg' alt="" /></a>
                    </div>
                    <div id="marquee-mobile" className="marquee mobile-only">
                        <div className="marquee-content">
                            <div className="d-flex align-items-center gap-2">
                                <i className="bi bi-volume-down" style={{padding:"0 4px",fontSize:"24px"}}></i>
                            </div>
                            <div className="running">
                                <div className="marquee-shadow"></div>
                                <marquee behavior="scroll" direction="" id="broadcast" style={{width:"100%"}}></marquee>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                            </div>
                        </div>
                    </div>
                    <div className="mslider mobile-only"></div>
                    <div className="login">
                        <div id="desktoplogin" className="desktop-only">
                            <form className="login-form form" role="form">
                                <div id="msgbox" style={{marginRight:"8px",color:"#b29459",fontSize:"1rem"}}></div>
                                <div className="input-group login-field">
                                    <span className="input-group-text prepend mobile-only "><div className="h-100 center"><i className="bi bi-person-circle prepend-icon"></i></div></span>
                                    <input type="text" name="username" className="form-control login-input-field username" placeholder="Nama Akun" aria-label="Username" />
                                </div>

                                <div id="marquee" className="marquee mobile-only">
                                    <div className="marquee-content">
                                        <div className="d-flex align-items-center gap-2">
                                            <i className="bi bi-megaphone-fill desktop-only"></i>
                                        </div>
                                        <div className="running">
                                            <div className="marquee-shadow"></div>
                                            <marquee behavior="" direction="" id="broadcast"></marquee>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-group login-field">
                                    <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-shield-lock-fill prepend-icon"></i></div></span>
                                    <input type="password" name="password" className="form-control login-input-field password" placeholder="Kata Sandi" aria-label="Password"/>
                                </div>
                                
                                <input type="hidden" name="verif" id="verifval" className="verifval" value="1"/>
                                <button type="submit" value="Masuk" className="btn btn-masuk fw-bold p-0 px-2 submit">Masuk</button>
                                <button type="button" className="btn btn-daftar btn-daftar-desktop fw-bold p-0 px-2 desktop-only" >Daftar</button>


                                <input type="hidden" name="task" value="login"/>
                                <input type="hidden" name="logindesktop" value="1eyq62+kErirTUgzuyd+ApK69BViaWwg3DATJZqV4is="/>
                            </form>
                        </div>

                        <div id="mobilelogin" className="mobile-only" style={{width:"100%"}}>
                            <form className="login-form form" style={{padding:"0px",paddingTop:"32px"}} role="form">
                                <div id="msgbox" style={{fontSize:"1rem",width:"100%"}}></div>
                                <div className="input-group login-field">
                                    <span className="input-group-text prepend mobile-only "><div className="h-100 center"><i className="bi bi-person-circle prepend-icon"></i></div></span>
                                    <input type="text" name="username" className="form-control login-input-field username" placeholder="Username" aria-label="Username" />
                                </div>
                                <div className="input-group login-field">
                                    <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-shield-lock-fill prepend-icon"></i></div></span>
                                    <input type="password" name="password" className="form-control login-input-field password" placeholder="Password" aria-label="Password" />
                                </div>
                                
                                <input type="hidden" name="verif" id="verifval" className="verifval" value="1"/>


                                <button type="submit" value="Masuk" className="btn btn-masuk fw-bold p-0 px-2 submit">Masuk</button>
                                <button type="button" className="btn btn-daftar btn-info btn-daftar-mobile fw-bold p-0 px-2 mobile-only" >Daftar Sekarang</button>


                                <input type="hidden" name="task" value="login"/>
                                <input type="hidden" name="loginmobile" value="1eyq62+kErirTUgzuyd+ApK69BViaWwg3DATJZqV4is="></input>
                            </form>
                        </div>
                        <div id="mobile-daftar-form" style={{width:"100%",display:"none"}}>
                            <div id="mobileregister">
                                <form id="formRegister" className="login-form  p-0 pt-4">
                                    <div id="statusRegister" style={{marginBottom:"5px",width:"100%"}}></div>
                                    <div className="prepend" style={{width:"100%",height:"36px",display:"grid",placeItems:"center",borderRadius:"3px", fontSize:"1.2rem",fontWeight:"bold"}}>DAFTAR</div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-person-circle prepend-icon"></i></div></span>
                                        <input type="text" className="form-control login-input-field" name="username" id="reg_username" placeholder="Username 6-16 karakter standar (A~Z, a~z, 0~9) dan tanpa spasi"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-shield-lock-fill prepend-icon"></i></div></span>
                                        <input type="password" className="form-control login-input-field" id="reg_pass" name="pass" placeholder="Password (6 karakter atau lebih)"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-shield-lock-fill prepend-icon"></i></div></span>
                                        <input type="password" className="form-control login-input-field" id="reg_passcon" name="passcon" placeholder="Password sekali lagi"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-envelope-fill prepend-icon"></i></div></span>
                                        <input type="text" className="form-control login-input-field" id="reg_email" name="email" placeholder="Email"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-telephone-fill prepend-icon"></i></div></span>
                                        <input type="text"  inputMode="numeric" className="form-control login-input-field"  id="reg_telpon" name="telpon" placeholder="Telpon"/>
                                    </div>

                                    <div className="input-group login-field">
                                        <select className="form-select login-input-field"  name="bank" id="reg_bank">
                                            <option value="">Pilih Bank</option>
                                                <option value='BCA'>BCA</option><option value='Mandiri'>Mandiri</option><option value='BNI'>BNI</option><option value='BRI'>BRI</option><option value='CIMB'>CIMB</option><option value='Danamon'>Danamon</option><option value='Permata'>Permata</option><option value='BJB'>BJB</option><option value='PANIN'>PANIN</option><option value='OCBC'>OCBC</option><option value='DKI'>DKI</option><option value='SUMUT'>SUMUT</option><option value='BSI'>BSI</option><option value='NEO'>NEO</option><option value='JAGO'>JAGO</option><option value='Jenius'>Jenius</option><option value='DANA'>DANA</option><option value='OVO'>OVO</option><option value='ShopeePay'>ShopeePay</option><option value='GOPAY'>GOPAY</option><option value='LinkAja'>LinkAja</option><option value='Luar'>Luar</option>                                                </select>
                                    </div>

                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend" ><div className="h-100 center"><i className="bi bi-piggy-bank-fill prepend-icon"></i></div></span>
                                        <input type="text" className="form-control login-input-field" name="rek" id="reg_rek" placeholder="No Rekening"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-person-fill prepend-icon"></i></div></span>
                                        <input type="text" className="form-control login-input-field" name="nama" id="reg_nama" placeholder="Nama Rekening"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-file-earmark-person-fill prepend-icon"></i></div></span>
                                        <input type="text" className="form-control login-input-field" name="ref" id="reg_ref" placeholder="Username Referal bila ada" />
                                    </div>
                                    <button type="submit" className="btn btn-masuk fw-bold p-0 px-2" value="Register"  id="buttonRegister">Daftar</button>
                                    <input type="hidden" name="task" value="register"/>
                                    <input type="hidden" name="regmobile" value="EajU6CnR0743xstbatLLfU1TGqTECQvfvK52pVLcCHE="/>
                                    <button type="button" className="btn btn-secondary fw-bold p-0 px-2"  value="Close"  id="buttonClose">Close</button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="menu">
                        <div className="menu-item desktop-only">
                            <label><a href="https://birutoto.nl/"><i className="bi bi-house-door-fill"></i></a></label>
                        </div>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>

                        <a href="https://birutoto.nl/?content=register" className="menu-item" onMouseOver={()=>showProducts('hover-togel')} onMouseOut={()=>hideProducts('hover-togel')}>
                            <label>TOGEL</label>
                        </a>

                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>


                        <a href="https://birutoto.nl/?content=slot" className="menu-item" onMouseOver={()=>showProducts('hover-slot')} onMouseOut={()=>hideProducts('hover-slot')}>
                            <label>SLOT</label>
                        </a>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                        <a href="https://birutoto.nl/?content=casino" className="menu-item" onMouseOver={()=>showProducts('hover-live')} onMouseOut={()=>hideProducts('hover-live')}>
                            <label>LIVE CASINO</label>
                        </a>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                        <a href="https://birutoto.nl/?content=sport" className="menu-item" onMouseOver={()=>showProducts('hover-sport')} onMouseOut={()=>hideProducts('hover-sport')}>
                            <label>SPORT</label>
                        </a>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                        <a href="https://birutoto.nl/?content=fish" className="menu-item" onMouseOver={()=>showProducts('hover-fishing')} onMouseOut={()=>hideProducts('hover-fishing')}>
                            <label>ARCADE</label>
                        </a>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                        <a href="https://birutoto.nl/?content=sabung" className="menu-item" onMouseOver={()=>showProducts('hover-sabung')} onMouseOut={()=>hideProducts('hover-sabung')}>
                            <label>SABUNG</label>
                        </a>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                        <a href="https://birutoto.nl/?content=interactive" className="menu-item" onMouseOver={()=>showProducts('hover-interactive')} onMouseOut={()=>hideProducts('hover-interactive')}>
                            <label>INTERACTIVE</label>
                        </a>

                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                    </div>
                    <div className="menu-mobile mobile-only" style={{width:"100%",overflowX:"hidden",position:"relative",borderTop:"3px solid var(--primary-color)",borderBottom:"3px solid var(--theme-color)"}}>
                        <div style={{position:"absolute",height:"100%",background:"var(--primary-color)",display:"grid",placeItems:"center",left:"0",padding:"0px 6px"}} >
                            <i className="bi bi-caret-left-fill" style={{fontSize:"16px",color:"var(--marquee-color)"}}></i>
                        </div>
                        <div style={{position:"absolute",height:"100%",background:"var(--primary-color)",display:"grid",placeItems:"center",right:"0",padding:"0px 6px"}} >
                            <i className="bi bi-caret-right-fill" style={{fontSize:"16px",color:"var(--marquee-color)"}}></i>
                        </div>
                        <div id="menu-mobile" style={{height:"68px",overflowX:"scroll",overflowY:"hidden",maxWidth:"100%",display:"block",marginLeft:"28px",marginRight:"28px",background:"linear-gradient(90deg, rgba(26,26,26,0.3) 0%, rgba(0,0,0,0.3) 10%, rgba(0,0,0,0.3) 100%), var(--primary-color)"}}>
                        </div>
                    </div>
                </div>
            </header>
            {/* <div id="marquee" className="marquee desktop-only">
                <div className="marquee-content">
                    <div className="d-flex align-items-center gap-2">
                        <div id="timenow" className="desktop-only" style={{whiteSpace:'nowrap'}}></div>
                        <i className="bi bi-megaphone-fill desktop-only"></i>
                    </div>
                    <div className="running" style={{paddingLeft:"8px",paddingRight:"32px"}}>
                    <Marquee style={{width:"100%"}}>
                        Marquee is an animation effect to create horizontal or vertical
                        scrolling
                    </Marquee>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <a href="https://birutoto.nl/?content=event">
                            <img src="https://img.viva88athenae.com//btnevent.png" alt="EVENT" height="24px"/>
                        </a>
                    </div>
                </div>
            </div> */}
            
            <div id="modal-wrapper" className="modal" style={{background:"none"}}>

                <div className="slideDown" id="informasi-menu">
                  <div className="row">
                    <div className="col-xs-3" style={{paddingRight:'0px',width:'164px'}}>
                        <div id="informasi-logo">
                            <img src="https://birutoto.nl/images/logoweb.png" width="120px" />
                        </div>
                        <div className="menuleft">
                            <a className="active btn-black" href="">TENTANG KAMI</a>
                            <a className="btn-black" href="">BANTUAN</a>
                            <a className="btn-black" href="">PERATURAN</a>
                            <a className="btn-black" href="">INFORMASI BANK</a>
                            <a className="btn-black" href="">HUBUNGI KAMI</a>
                            <a className="btn-black" href="">KEBIJAKAN PRIVASI</a>
                            <a className="btn-black" href="">PERSETUJUAN COOKIES</a>
                        </div>
                   </div>
                   <div className="col-xs-9" id="informasi-content" style={{position:'relative'}}>
                        <div  className="closebox" title="Close Informasi">&times;</div>
                        <div style={{margin:'32px 0',height:'600px',overflow:'auto',zIndex:'1'}} id="content2">
                        </div>
                    </div>
                </div>
              </div>
            </div>
            
            <div
            dangerouslySetInnerHTML={{
                __html: dataContent ? dataContent.data : null
            }}></div>
           
            <div className="provider">
                <div className="provider-group">
                    <img src="https://img.viva88athenae.com//assets/categories.png" className="desktop-only" alt="" style={{paddingBottom:'16px'}} />
                    <img src="https://img.viva88athenae.com//assets/providers.png" className="desktop-only" alt=""/>
                    <img src="https://img.viva88athenae.com//mproviders.png" alt="" style={{width:"100%"}} className="mobile-only"/>
                </div>
            </div>
            <div className="provider">
                <div className="provider-group">
                    <img src="https://img.viva88athenae.com//assets/categories.png" className="desktop-only" alt="" style={{paddingBottom:'16px'}} />
                    <img src="https://img.viva88athenae.com//assets/providers.png" className="desktop-only" alt=""/>
                    <img src="https://img.viva88athenae.com//mproviders.png" alt="" style={{width:"100%"}} className="mobile-only"/>
                </div>
            </div>
            <div className="transaksi">
                <div>
                    <div className="transaksi-kontak">
                        <div className="d-flex flex-column w-100">
                            <div className="transaksi-title">
                                <div className="transaksi-title-group">
                                    <div className="fitur-header">Transaksi</div>
                                    <div className="desktop-only" style={{lineHeight:".8rem",color:'var(--primary-text-color)'}}>Kami Menerima Pembayaran Dibawah Ini</div>
                                </div>

                                <div className="devider desktop-only" style={{width:'320px'}}></div>
                            </div>

                            <div className="transaksi-content">
                                       
                                <div className="transaksi-group">
                                    <div className="transaksi-bank">

                                        <div className="transaksi-wrapper">
                                            <div className="transaksi-name">Bank Lokal</div>

                                            <div id="transaksi-bank-indicators" className="carousel slide" data-bs-ride="carousel">
                                                <div className="carousel-indicators" style={{bottom:"-32px",marginBottom:'0px'}}>
                                                </div>
                                                <div className="carousel-inner" >
                                                    <div className="carousel-item active" style={{height:"144px"}}>
                                                        <div className="transaksi-list-bank">
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">BCA</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">Mandiri</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">BNI</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">BRI</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">CIMB</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">Danamon</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">Permata</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">BJB</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">PANIN</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">OCBC</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">DKI</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">SUMUT</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">BSI</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">NEO</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                            <div className="transaksi-item">
                                                                <div className="transaksi-header">JAGO</div>
                                                                <div className="transaksi-status status-green"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="transaksi-other">
                                        <div className="desktop-only" style={{height:"100%",width:"2px",background:"#888888"}}></div>

                                            <div className="transaksi-wrapper" style={{maxWidth:'284px'}}>

                                                <div className="transaksi-name">E money & Pulsa</div>


                                                <div id="transaksi-other-indicators" className="carousel slide" data-bs-ride="carousel">
                                                    <div className="carousel-indicators" style={{bottom:"-32px",marginBottom:"0px"}}>
                                                    </div>
                                                    <div className="carousel-inner" >
                                                        <div className="carousel-item active">
                                                            <div className="transaksi-list-other">
                                                                <div className="transaksi-item">
                                                                    <div className="transaksi-header">Jenius</div>
                                                                    <div className="transaksi-status status-blue"></div>
                                                                </div>
                                                                <div className="transaksi-item">
                                                                    <div className="transaksi-header">DANA</div>
                                                                    <div className="transaksi-status status-blue"></div>
                                                                </div>
                                                                <div className="transaksi-item">
                                                                    <div className="transaksi-header">OVO</div>
                                                                    <div className="transaksi-status status-blue"></div>
                                                                </div>
                                                                <div className="transaksi-item">
                                                                    <div className="transaksi-header">ShopeePay</div>
                                                                    <div className="transaksi-status status-blue"></div>
                                                                </div>
                                                                <div className="transaksi-item">
                                                                    <div className="transaksi-header">GOPAY</div>
                                                                    <div className="transaksi-status status-blue"></div>
                                                                </div>
                                                                <div className="transaksi-item">
                                                                    <div className="transaksi-header">LinkAja</div>
                                                                    <div className="transaksi-status status-blue"></div>
                                                                </div>
                                                                <div className="transaksi-item">
                                                                    <div className="transaksi-header">Luar</div>
                                                                    <div className="transaksi-status status-blue"></div>
                                                                </div>
                                                                <div className="transaksi-item">
                                                                    <div className="transaksi-header">Telkomsel</div>
                                                                    <div className="transaksi-status status-blue"></div>
                                                                </div>
                                                                <div className="transaksi-item">
                                                                    <div className="transaksi-header">Axiata</div>
                                                                    <div className="transaksi-status status-blue"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        <div className="desktop-only" style={{height:"100%",width:"2px",background:"#888888"}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column desktop-only" style={{width:"180px"}}>
                            <div className="transaksi-title">
                                <div className="transaksi-title-group">
                                    <div className="fitur-header">Kontak Kami</div>
                                </div>
                                <div className="devider desktop-only"></div>
                            </div>
                            <div id="kontak-kami-indicators" className="carousel slide" data-bs-ride="carousel" style={{paddingTop:"14px"}}>
                                <div className="carousel-indicators" style={{bottom:"-33px",marginBottom:"0px"}}>
                                </div>
                                <div className="carousel-inner" style={{height:"164px"}}>
                                    <div className="carousel-item active">
                                        <div>
                                            <div style={{display:'flex',alignItems:"center",gap:"8px",marginBottom:'12px'}}>
                                                <img src="https://img.viva88athenae.com/kontak/wa.png" width="20"/>
                                                <div className="d-flex flex-column">
                                                    <div style={{color:"#888888"}}>WHATSAPP</div>
                                                    <div style={{lineHeight:".9rem",color:'var(--primary-text-color)'}}>+62 852 8060 2669</div>

                                                </div>
                                            </div>


                                            <div style={{display:'flex',alignItems:"center",gap:"8px",marginBottom:'12px'}}>
                                                <img src="https://img.viva88athenae.com/kontak/line.png" width="20"/>
                                                <div className="d-flex flex-column">
                                                    <div style={{color:"#888888"}}>LINE</div>
                                                    <div style={{lineHeight:".9rem",color:'var(--primary-text-color)'}}>BIRUTOTO</div>

                                                </div>
                                            </div>


                                            <div style={{display:'flex',alignItems:"center",gap:"8px",marginBottom:'12px'}}>
                                                <img src="https://img.viva88athenae.com/kontak/skype.png" width="20"/>
                                                <div className="d-flex flex-column">
                                                    <div style={{color:"#888888"}}>SKYPE</div>
                                                    <div style={{lineHeight:".9rem",color:'var(--primary-text-color)'}}>+855883720676</div>

                                                </div>
                                            </div>


                                            <div style={{display:'flex',alignItems:"center",gap:"8px",marginBottom:'12px'}}>
                                                <img src="https://img.viva88athenae.com/kontak/fb.png" width="20"/>
                                                <div className="d-flex flex-column">
                                                    <div style={{color:"#888888"}}>FACEBOOK</div>
                                                    <div style={{lineHeight:".9rem",color:'var(--primary-text-color)'}}>BIRUTOTO</div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="guidelines">
                <div className="guidelines-content desktop-only">
                    <img src="https://img.viva88athenae.com//guidelines.png" />
                </div>
            </div>
            <div className="bantuan">
                <ul className="bantuan-content">
                    <li className="bantuan-item" >TENTANG KAMI</li>
                    <div className="bantuan-devider desktop-only">|</div>
                    <li className="bantuan-item" >BANTUAN</li>
                    <div className="bantuan-devider desktop-only">|</div>
                    <li className="bantuan-item" >PERATURAN</li>
                    <div className="bantuan-devider desktop-only">|</div>
                    <li className="bantuan-item" >INFORMASI BANK</li>
                    <div className="bantuan-devider desktop-only">|</div>
                    <li className="bantuan-item" >HUBUNGI KAMI</li>
                    <div className="bantuan-devider desktop-only">|</div>
                    <li className="bantuan-item" >KEBIJAKAN PRIVASI</li>
                    <div className="bantuan-devider desktop-only">|</div>
                    <li className="bantuan-item" >PERSETUJUAN COOKIES</li>
                </ul>
            </div>
            <footer className="footer">
                <div>
                    <html>
                    <head>
                    <title></title>
                    </head>
                    <body>
                    <center>
                    <h1 style={{color: "#d60202",fontSize:"30px"}}><strong>PLATFORM GAME
                    ONLINE TERBAIK DAN TERPERCAYA SAAT INI | BIRUTOTO SITUS GAME ONLINE
                    TERBESAR DAN NOMOR #1 DI INDONESIA</strong></h1>
                    </center>
                    <p style={{color: "#ffffff",fontSize:"14px"}}><a href=
                    "https://birutoto999.com/">Birutoto</a> merupakan situs game online
                    terbaik dan tepercaya saat ini, berdiri sejak tahun 2015 dan
                    memiliki ribuan member aktif setiap harinya tentu menjadikan salah
                    satu unggulan birutoto sebagai situs paling terpercaya terbukti
                    membayar berapapun kemenangan member secara lunas. Dengan menu dan
                    fitur yang mudah di pahami tentu mempermudah kamu untuk bergabung
                    dan bermain berbagai jenis game online di birutoto hanya dengan
                    menggunakan 1 ID saja.</p>
                    <p style={{color: "#ffffff",fontSize:"14px"}}>Birutoto dapat kamu
                    akses melalui Handphone atau Komputer kamu dengan mengetik kata
                    kunci di mesin search engine seperti google dengan menulis
                    “birutoto” maka kamu sudah bisa menemukan situs paling terpercaya
                    dan terbaik saat ini yaitu birutoto.</p>
                    <center>
                    <h2 style={{color: "#d60202",fontSize:"26px"}}>Daftar Situs Game Online
                    Mudah Menang</h2>
                    </center>
                    <p style={{color: "#ffffff",fontSize:"14px"}}>Untuk dapat bermain game
                    online di <a href="https://149.28.132.2/">Birutoto</a> cara nya
                    tentu sangat mudah, cukup mendaftar dan melakukan setoran kredit
                    minimal hanya Rp 5.000 ( lima ribu rupiah ) kamu sudah bisa memilih
                    dan bermain game online di birutoto hanya dengan menggunakan 1 ID
                    saja.</p>
                    <p style={{color: "#ffffff",fontSize:"14px"}}><a href=
                    "https://birutoto7.top/">Birutoto</a> dapat kamu akses selama 24
                    jam nonstop dan tentu tidak perlu khawatir jika kamu mengalami
                    kesulitan atau kendala saat bermain game online di birutoto, karena
                    ada CS 24 Jam birutoto yang siap membantu serta melayani kamu
                    dengan sepenuh hati.</p>
                    <div style={{position: "fixed",bottom: "260px",left: "5px",zindex: "0",opacity: "0.98"}}>
                    <a href="https://tanpabatas.vip/regH" target="_blank" rel=
                    "noopener"><img className="qrisbutton" src=
                    "https://telegra.ph/file/13cd9f6cf67645e95b1fb.gif" alt="HIJAUTOTO"
                    width="70" height="70"/></a></div>
                    <div style={{position: "fixed",bottom: "100px",left: "5px",zindex: "15",opacity: "0.98"}}>
                    <a href="https://warna.blue/RTP" target="_blank" rel=
                    "noopener"><img className="rtpbutton" src=
                    "https://telegra.ph/file/4be7055243af0a746b93b.gif" alt=
                    "RTP BIRUTOTO" width="70" height="70"/></a></div>
                    <div style={{position: "fixed",bottom: "180px",left: "5px",zindex: "15",opacity: "0.98"}}>
                    <a href="https://warna.blue/birujp/" target="_blank" rel=
                    "noopener"><img className="buktibutton" src=
                    "https://telegra.ph/file/ecb8dd1facfbcc18f6c58.gif" alt=
                    "BUKTI WD BIRUTOTO" width="70" height="70"/></a></div>
                    </body>
                    </html>                
                </div>


                <div className="footer-group">
                    <div className="copyright">
                        <div style={{whiteSpace:"nowrap"}}>© 2015 - 2024 Copyright BIRUTOTO.</div>
                        <div>All Rights Reserved.</div>
                    </div>
                    <div className="desktop-only">
                        <div className="d-flex gap-1">
                            <div className="btn-footer" >MOBILE</div>
                            <div className="btn-footer" >WAP</div>
                        </div>
                    </div>
                    <img className="mobile-only" style={{width: "100%"}} src="https://img.viva88athenae.com//guidelines.png" alt=""/>
                </div>
            </footer>
            <div id="mySidenav" className="sidenav desktop-only">
                <div id="nav">
                    <div style={{display: "flex",alignItems:"start"}}>
                        <div style={{height: "246px",width: "64px",background: 'var(--primary-color)',borderRadius:"8px 0px 0px 8px",overflow: "hidden",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                            <img src="https://birutoto.nl/assets/hubungi.png?11"/>
                        </div>
                    </div>
                    <div className="ptsans" style={{fontSize: "1rem",background: "white",borderRadius: "0px 5px 5px 5px",overflow: "hidden",width: "160px"}}>
                        <div id="" style={{padding:"4px"}}>
                            <img src="https://birutoto.nl/assets/24h.png" style={{width: "100%"}}/>
                        </div>

                        <div id="kontak-content" style={{display: "flex", flexDirection: "column", width: "100%", fontSize: ".8rem"}}>
                            <div style={{display: "grid", placeItems: "center", fontWeight: "bold", fontSize: "1rem"}}>MEMBER SERVICE</div>
                            <div style={{padding: "4px", display: "flex", alignItems: "center", gap: "8px", borderBottom: "1px solid #e0e0e0"}}>
                                <img src="https://img.viva88athenae.com/kontak/wa.png" width="20"/><span>+62 852 8060 2669</span>
                            </div>
                            <div style={{padding: "4px", display: "flex", alignItems: "center", gap: "8px", borderBottom: "1px solid #e0e0e0"}}>
                                <img src="https://img.viva88athenae.com/kontak/line.png" width="20"/><span><span>ID:</span>BIRUTOTO</span>
                            </div>
                            <div style={{padding: "4px", display: "flex", alignItems: "center", gap: "8px", borderBottom: "1px solid #e0e0e0"}}>
                                <img src="https://img.viva88athenae.com/kontak/skype.png" width="20"/><span>+855883720676</span>
                            </div>
                            <div style={{padding: "4px", display: "flex", alignItems: "center", gap: "8px", borderBottom: "1px solid #e0e0e0"}}>
                                <img src="https://img.viva88athenae.com/kontak/fb.png" width="20"/><span>BIRUTOTO</span>
                            </div>
                        </div>

                        <div style={{margin: "8px"}}>
                            <input type="button" value="DAFTAR SEKARANG" style={{width: "100%", borderRadius: "5px", fontSize: ".875rem", height: "40px", border: "none", fontWeight: "bold", background: "black", color: "white"}}  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );

}

export default Hasil;