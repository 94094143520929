import axios from 'axios';
import configData from "../config.json";

const API_URL = configData.REACT_APP_API_URL;
const LOTTO_URL = `${API_URL}/content/getLotto`
const CONTENT_URL = `${API_URL}/content/getContent`
const TRANSAKSI_URL = `${API_URL}/content/getTransaksi`
const HUBUNGI_URL = `${API_URL}/content/getHubungi`
const MENU_URL = `${API_URL}/content/getMenu`
const FOOTER_URL = `${API_URL}/content/getFooter`

class ContentService {
  lotto() {
    return axios.get(LOTTO_URL)
      .then(response => {
        return response;
      });
  }
  getFooter() {
    return axios.get(FOOTER_URL)
      .then(response => {
        return response;
      });
  }
  getHubungi() {
    return axios.get(HUBUNGI_URL)
      .then(response => {
        return response;
      });
  }
  getTransaksi() {
    return axios.get(TRANSAKSI_URL)
      .then(response => {
        return response;
      });
  }
  getMenu() {
    return axios.get(MENU_URL)
      .then(response => {
        return response;
      });
  }
  getContent($content,$pid) {
    return axios.get(CONTENT_URL+'/'+$content+($pid?'/'+$pid:''))
      .then(response => {
        return response;
      });
  }
}

export default new ContentService();