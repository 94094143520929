import {useState, useEffect} from "react";
import styled, { keyframes } from "styled-components";
import ContentService from '../services/content.service'
import configData from "../config.json";
const IMAGE_URL = configData.REACT_APP_IMAGE_URL;
const APP_URL = configData.REACT_APP_URL;

const queryParams = new URLSearchParams(window.location.search);
const content = queryParams.get("content");
const pid = queryParams.get("pid");
const provider = queryParams.get("provider");
function Home() {
    const [lotto, setLotto] = useState([])
    const [transaksi, setTransaksi] = useState(null)
    const [hubungi, setHubungi] = useState(null)
    const [menuDesktop, setMenuDesktop] = useState(null)
    const [menuMobile, setMenuMobile] = useState(null)
    const [dataContent, setDataContent] = useState(null)
    const [dataContentFoorter, setDataContentFoorter] = useState(null)
    
    const getContent = async () => {
        if(content === 'slot'){
            try {
                const {data: _dataContent} = await ContentService.getContent(content,provider);
                
                setDataContent(_dataContent.data);
            } catch (error) {
                console.error(error)
            }
        }else{
            try {
                const {data: _dataContent} = await ContentService.getContent(content,pid);
                
                setDataContent(_dataContent.data);
            } catch (error) {
                console.error(error)
            }
        }
        
    }
    const getContentFooter = async () => {
        try {
            const {data: _dataContentFooter} = await ContentService.getFooter();
            
            setDataContentFoorter(_dataContentFooter.data);
        } catch (error) {
            console.error(error)
        }
        
    }

    const getLotto = async () => {
        try {
            const {data: dataLotto} = await ContentService.lotto();
            
            setLotto(dataLotto);
        } catch (error) {
            console.error(error)
            
        }
    }
    const getHubungi = async () => {
        try {
            const {data: dataHubungi} = await ContentService.getHubungi();
            
            setHubungi(dataHubungi);
        } catch (error) {
            console.error(error)
            
        }
    }
    const getTransaksi = async () => {
        try {
            const {data: dataTransaksi} = await ContentService.getTransaksi();
            
            setTransaksi(dataTransaksi.data);
        } catch (error) {
            console.error(error)
            
        }
    }
    const getMenu = async () => {
        try {
            const {data: dataMenu} = await ContentService.getMenu();
            
            setMenuDesktop(dataMenu.dataDestop);
            setMenuMobile(dataMenu.dataMobile);
        } catch (error) {
            console.error(error)
            
        }
    }
    const banner = '\
    <div class="contentdata">\
        <div id="content-primary" class="p-0">\
            <div class="desktop-only">\
                <div id="mobile-slider" class="carousel slide" data-bs-ride="carousel" style="height:100%;">\
                    <div class="carousel-indicators">\
                        <button type="button" data-bs-target="#mobile-slider" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>\
                        <button type="button" data-bs-target="#mobile-slider" data-bs-slide-to="1" aria-label="Slide 2"></button>\
                        <button type="button" data-bs-target="#mobile-slider" data-bs-slide-to="2" aria-label="Slide 3"></button>\
                    </div>\
                    <div class="carousel-inner" style="height:100%;">\
                        <div class="carousel-item active" style="height:100%;">\
                            <img src="'+IMAGE_URL+"indo-1.jpeg?v=1"+'"  class="d-block w-100 banner" alt="MARHABAN-YA-RAMADHAN-BIRUTOTO" style="height:100%;" />\
                        </div>\
                        <div class="carousel-item " style="height:100%;">\
                            <img src="'+IMAGE_URL+"indo-2.jpeg?v=1"+'"  class="d-block w-100 banner" alt="klaim-thr-birutoto-2024" style="height:100%;"/>\
                        </div>\
                        <div class="carousel-item " style="height:100%;">\
                            <img src="'+IMAGE_URL+"indo-3.jpeg?v=1"+'"  class="d-block w-100 banner" alt="WELCOME TO BIRUTOTO" style="height:100%;"/>\
                        </div>\
                    </div>\
                </div>\
            </div>\
            <div class="mobile-only">\
                <div class="banner shadow" style="margin:20px 20px 20px 20px;border:solid 2px #FFF;borderRadius:20px;boxShadow:rgba(0,0,0,1) 0px 0px 10px 0px;position:relative;">\
                    <img src="'+IMAGE_URL+"indo-1.jpeg?v=1"+'" alt="kemenangan-member-birutoto" style="borderRadius:20px;top:0;left:0;width:100%;height:100%;position:absolute;"/>\
                </div>\
            </div>\
        </div>\
    </div>\
    ';
    useEffect(() => {
        getHubungi()
        getMenu()
        getLotto()
        getTransaksi()
        getContentFooter()
        if(content){
            getContent()
        }else{
            setDataContent(banner);
        }
    }, []);
    var showIDs = [];
    
    const showProducts = (hoverID) => {
        while(showIDs.length > 0) document.getElementById(showIDs.pop()).style.display = "none";

        // document.getElementById('hover').classList.add("hover-show");
        document.getElementById('hover').style.transitionDelay = "0s";
        document.getElementById('hover').style.visibility = "visible";
        document.getElementById('hover').style.opacity = "1";
        document.getElementById(hoverID).style.display = "flex";

        showIDs.push(hoverID);
    }
    const hideProducts = (hoverID) => {
        document.getElementById('hover').style.visibility = "hidden";
        document.getElementById('hover').style.opacity = "0";
        document.getElementById('hover').style.transitionDelay = "0.5s";
    }


    const movingText = keyframes`
    0%{
    transform:translateX(80%);
    }
    100%{
    transform:translateX(-80%);
    }
    `;

    const Marquee = styled.span`
    @media (prefers-reduced-motion: no-preference) {
        animation: ${movingText} 15s linear infinite;
        :hover {
        animation-play-state: paused;
        }
    }
    cursor: default;
    `;
    const redirectRegister = () => {
        window.location.href = APP_URL+'/?content=register';
        
      };
return (
    <>
    
        <div id="hover" className="hover desktop-only"
            dangerouslySetInnerHTML={{
            __html: menuDesktop ? menuDesktop : null
        }}></div>
         <div className="mobile-only"
            dangerouslySetInnerHTML={{
            __html: menuMobile ? menuMobile : null
        }}></div>
        <div id="top" className="wrapper">
            <header className="header">
                <div className="header-content">
                    <div className="logo" href="/">
                        <a href='/'><img src={IMAGE_URL+"okayidr-logo.png"}   className='logoimg' alt="" /></a>
                    </div>
                    <div id="marquee-mobile" className="marquee mobile-only">
                        <div className="marquee-content">
                            <div className="d-flex align-items-center gap-2">
                                <i className="bi bi-volume-down" style={{padding:"0 4px",fontSize:"24px"}}></i>
                            </div>
                            <div className="running">
                                <div className="marquee-shadow"></div>
                                <marquee behavior="scroll" direction="" id="broadcast" style={{width:"100%"}}></marquee>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                            </div>
                        </div>
                    </div>
                    <div className="mslider mobile-only"></div>
                    <div className="login">
                        <div id="desktoplogin" className="desktop-only">
                            <form className="login-form form" role="form">
                                <div id="msgbox" style={{marginRight:"8px",color:"#b29459",fontSize:"1rem"}}></div>
                                <div className="input-group login-field">
                                    <span className="input-group-text prepend mobile-only "><div className="h-100 center"><i className="bi bi-person-circle prepend-icon"></i></div></span>
                                    <input type="text" name="username" id="login_username" className="form-control login-input-field username" placeholder="Nama Akun" aria-label="Username" />
                                </div>

                                <div id="marquee" className="marquee mobile-only">
                                    <div className="marquee-content">
                                        <div className="d-flex align-items-center gap-2">
                                            <i className="bi bi-megaphone-fill desktop-only"></i>
                                        </div>
                                        <div className="running">
                                            <div className="marquee-shadow"></div>
                                            <marquee behavior="" direction="" id="broadcast"></marquee>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-group login-field">
                                    <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-shield-lock-fill prepend-icon"></i></div></span>
                                    <input type="password" name="password" id="login_password" className="form-control login-input-field password" placeholder="Kata Sandi" aria-label="Password"/>
                                </div>
                                
                                <input type="hidden" name="verif" id="verifval" className="verifval" value="1"/>
                                <button type="submit" value="Masuk" className="btn btn-masuk fw-bold p-0 px-2 submit">Masuk</button>
                                <button type="button" className="btn btn-daftar btn-daftar-desktop fw-bold p-0 px-2 desktop-only" onClick={redirectRegister}>Daftar</button>


                                <input type="hidden" name="task" value="login"/>
                                <input type="hidden" name="logindesktop" value="1eyq62+kErirTUgzuyd+ApK69BViaWwg3DATJZqV4is="/>
                            </form>
                        </div>

                        <div id="mobilelogin" className="mobile-only" style={{width:"100%"}}>
                            <form className="login-form form" style={{padding:"0px",paddingTop:"32px"}} role="form">
                                <div id="msgbox" style={{fontSize:"1rem",width:"100%"}}></div>
                                <div className="input-group login-field">
                                    <span className="input-group-text prepend mobile-only "><div className="h-100 center"><i className="bi bi-person-circle prepend-icon"></i></div></span>
                                    <input type="text" name="username" className="form-control login-input-field username" placeholder="Username" aria-label="Username" />
                                </div>
                                <div className="input-group login-field">
                                    <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-shield-lock-fill prepend-icon"></i></div></span>
                                    <input type="password" name="password" className="form-control login-input-field password" placeholder="Password" aria-label="Password" />
                                </div>
                                
                                <input type="hidden" name="verif" id="verifval" className="verifval" value="1"/>


                                <button type="submit" value="Masuk" className="btn btn-masuk fw-bold p-0 px-2 submit">Masuk</button>
                                <button type="button" className="btn btn-daftar btn-info btn-daftar-mobile fw-bold p-0 px-2 mobile-only" >Daftar Sekarang</button>


                                <input type="hidden" name="task" value="login"/>
                                <input type="hidden" name="loginmobile" value="1eyq62+kErirTUgzuyd+ApK69BViaWwg3DATJZqV4is="></input>
                            </form>
                        </div>
                        <div id="mobile-daftar-form" style={{width:"100%",display:"none"}}>
                            <div id="mobileregister">
                                <form id="formRegister" className="login-form  p-0 pt-4">
                                    <div id="statusRegister" style={{marginBottom:"5px",width:"100%"}}></div>
                                    <div className="prepend" style={{width:"100%",height:"36px",display:"grid",placeItems:"center",borderRadius:"3px", fontSize:"1.2rem",fontWeight:"bold"}}>DAFTAR</div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-person-circle prepend-icon"></i></div></span>
                                        <input type="text" className="form-control login-input-field" name="username" id="reg_username" placeholder="Username 6-16 karakter standar (A~Z, a~z, 0~9) dan tanpa spasi"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-shield-lock-fill prepend-icon"></i></div></span>
                                        <input type="password" className="form-control login-input-field" id="reg_pass" name="pass" placeholder="Password (6 karakter atau lebih)"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-shield-lock-fill prepend-icon"></i></div></span>
                                        <input type="password" className="form-control login-input-field" id="reg_passcon" name="passcon" placeholder="Password sekali lagi"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-envelope-fill prepend-icon"></i></div></span>
                                        <input type="text" className="form-control login-input-field" id="reg_email" name="email" placeholder="Email"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-telephone-fill prepend-icon"></i></div></span>
                                        <input type="text"  inputMode="numeric" className="form-control login-input-field"  id="reg_telpon" name="telpon" placeholder="Telpon"/>
                                    </div>

                                    <div className="input-group login-field">
                                        <select className="form-select login-input-field"  name="bank" id="reg_bank">
                                            <option value="">Pilih Bank</option>
                                                <option value='BCA'>BCA</option><option value='Mandiri'>Mandiri</option><option value='BNI'>BNI</option><option value='BRI'>BRI</option><option value='CIMB'>CIMB</option><option value='Danamon'>Danamon</option><option value='Permata'>Permata</option><option value='BJB'>BJB</option><option value='PANIN'>PANIN</option><option value='OCBC'>OCBC</option><option value='DKI'>DKI</option><option value='SUMUT'>SUMUT</option><option value='BSI'>BSI</option><option value='NEO'>NEO</option><option value='JAGO'>JAGO</option><option value='Jenius'>Jenius</option><option value='DANA'>DANA</option><option value='OVO'>OVO</option><option value='ShopeePay'>ShopeePay</option><option value='GOPAY'>GOPAY</option><option value='LinkAja'>LinkAja</option><option value='Luar'>Luar</option>                                                </select>
                                    </div>

                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend" ><div className="h-100 center"><i className="bi bi-piggy-bank-fill prepend-icon"></i></div></span>
                                        <input type="text" className="form-control login-input-field" name="rek" id="reg_rek" placeholder="No Rekening"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-person-fill prepend-icon"></i></div></span>
                                        <input type="text" className="form-control login-input-field" name="nama" id="reg_nama" placeholder="Nama Rekening"/>
                                    </div>
                                    <div className="input-group login-field">
                                        <span className="input-group-text mobile-only prepend"><div className="h-100 center"><i className="bi bi-file-earmark-person-fill prepend-icon"></i></div></span>
                                        <input type="text" className="form-control login-input-field" name="ref" id="reg_ref" placeholder="Username Referal bila ada" />
                                    </div>
                                    <button type="submit" className="btn btn-masuk fw-bold p-0 px-2" value="Register"  id="buttonRegister">Daftar</button>
                                    <input type="hidden" name="task" value="register"/>
                                    <input type="hidden" name="regmobile" value="EajU6CnR0743xstbatLLfU1TGqTECQvfvK52pVLcCHE="/>
                                    <button type="button" className="btn btn-secondary fw-bold p-0 px-2"  value="Close"  id="buttonClose">Close</button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="menu">
                        <div className="menu-item desktop-only">
                            <label><a href="/"><i className="bi bi-house-door-fill"></i></a></label>
                        </div>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>

                        <a href="/?content=register" className="menu-item" onMouseOver={()=>showProducts('hover-togel')} onMouseOut={()=>hideProducts('hover-togel')}>
                            <label>TOGEL</label>
                        </a>

                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>


                        <a href="/?content=slot" className="menu-item" onMouseOver={()=>showProducts('hover-slot')} onMouseOut={()=>hideProducts('hover-slot')}>
                            <label>SLOT</label>
                        </a>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                        <a href="/?content=casino" className="menu-item" onMouseOver={()=>showProducts('hover-live')} onMouseOut={()=>hideProducts('hover-live')}>
                            <label>LIVE CASINO</label>
                        </a>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                        <a href="/?content=sport" className="menu-item" onMouseOver={()=>showProducts('hover-sport')} onMouseOut={()=>hideProducts('hover-sport')}>
                            <label>SPORT</label>
                        </a>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                        <a href="/?content=fish" className="menu-item" onMouseOver={()=>showProducts('hover-fishing')} onMouseOut={()=>hideProducts('hover-fishing')}>
                            <label>ARCADE</label>
                        </a>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                        <a href="/?content=sabung" className="menu-item" onMouseOver={()=>showProducts('hover-sabung')} onMouseOut={()=>hideProducts('hover-sabung')}>
                            <label>SABUNG</label>
                        </a>
                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                        <a href="/?content=interactive" className="menu-item" onMouseOver={()=>showProducts('hover-interactive')} onMouseOut={()=>hideProducts('hover-interactive')}>
                            <label>INTERACTIVE</label>
                        </a>

                        <span className="desktop-only" style={{color:"var(--menu-item-color)"}}>|</span>
                    </div>
                    <div className="menu-mobile mobile-only" style={{width:"100%",overflowX:"hidden",position:"relative",borderTop:"3px solid var(--primary-color)",borderBottom:"3px solid var(--theme-color)"}}>
                        <div style={{position:"absolute",height:"100%",background:"var(--primary-color)",display:"grid",placeItems:"center",left:"0",padding:"0px 6px"}} >
                            <i className="bi bi-caret-left-fill" style={{fontSize:"16px",color:"var(--marquee-color)"}}></i>
                        </div>
                        <div style={{position:"absolute",height:"100%",background:"var(--primary-color)",display:"grid",placeItems:"center",right:"0",padding:"0px 6px"}} >
                            <i className="bi bi-caret-right-fill" style={{fontSize:"16px",color:"var(--marquee-color)"}}></i>
                        </div>
                        <div id="menu-mobile" style={{height:"68px",overflowX:"scroll",overflowY:"hidden",maxWidth:"100%",display:"block",marginLeft:"28px",marginRight:"28px",background:"linear-gradient(90deg, rgba(26,26,26,0.3) 0%, rgba(0,0,0,0.3) 10%, rgba(0,0,0,0.3) 100%), var(--primary-color)"}}>
                        </div>
                    </div>
                </div>
            </header>
            {/* <div id="marquee" className="marquee desktop-only">
                <div className="marquee-content">
                    <div className="d-flex align-items-center gap-2">
                        <div id="timenow" className="desktop-only" style={{whiteSpace:'nowrap'}}></div>
                        <i className="bi bi-megaphone-fill desktop-only"></i>
                    </div>
                    <div className="running" style={{paddingLeft:"8px",paddingRight:"32px"}}>
                    <Marquee style={{width:"100%"}}>
                        Marquee is an animation effect to create horizontal or vertical
                        scrolling
                    </Marquee>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <a href="https://birutoto.nl/?content=event">
                            <img src="https://img.viva88athenae.com//btnevent.png" alt="EVENT" height="24px"/>
                        </a>
                    </div>
                </div>
            </div> */}
            
            <div id="modal-wrapper" className="modal" style={{background:"none"}}>

                <div className="slideDown" id="informasi-menu">
                  <div className="row">
                    <div className="col-xs-3" style={{paddingRight:'0px',width:'164px'}}>
                        <div id="informasi-logo">
                            <img src="https://birutoto.nl/images/logoweb.png" width="120px" />
                        </div>
                        <div className="menuleft">
                            <a className="active btn-black" href="">TENTANG KAMI</a>
                            <a className="btn-black" href="">BANTUAN</a>
                            <a className="btn-black" href="">PERATURAN</a>
                            <a className="btn-black" href="">INFORMASI BANK</a>
                            <a className="btn-black" href="">HUBUNGI KAMI</a>
                            <a className="btn-black" href="">KEBIJAKAN PRIVASI</a>
                            <a className="btn-black" href="">PERSETUJUAN COOKIES</a>
                        </div>
                   </div>
                   <div className="col-xs-9" id="informasi-content" style={{position:'relative'}}>
                        <div  className="closebox" title="Close Informasi">&times;</div>
                        <div style={{margin:'32px 0',height:'600px',overflow:'auto',zIndex:'1'}} id="content2">
                        </div>
                    </div>
                </div>
              </div>
            </div>
            
            {/* <div
            dangerouslySetInnerHTML={{
                __html: banner ? banner : null
            }}></div> */}
            <div
            dangerouslySetInnerHTML={{
                __html: dataContent ? dataContent : null
            }}></div>
            <div
            dangerouslySetInnerHTML={{
                __html: lotto ? lotto.togelDesktop : null
            }}></div>
            <div
            dangerouslySetInnerHTML={{
                __html: lotto ? lotto.togelMobile : null
            }}></div>
            <div className="provider">
                <div className="provider-group">
                    <img src="https://img.viva88athenae.com//assets/categories.png" className="desktop-only" alt="" style={{paddingBottom:'16px'}} />
                    <img src="https://img.viva88athenae.com//assets/providers.png" className="desktop-only" alt=""/>
                    <img src="https://img.viva88athenae.com//mproviders.png" alt="" style={{width:"100%"}} className="mobile-only"/>
                </div>
            </div>
            <div className="provider">
                <div className="provider-group">
                    <img src="https://img.viva88athenae.com//assets/categories.png" className="desktop-only" alt="" style={{paddingBottom:'16px'}} />
                    <img src="https://img.viva88athenae.com//assets/providers.png" className="desktop-only" alt=""/>
                    <img src="https://img.viva88athenae.com//mproviders.png" alt="" style={{width:"100%"}} className="mobile-only"/>
                </div>
            </div>
            <div
            dangerouslySetInnerHTML={{
                __html: transaksi ? transaksi : null
            }}></div>

            <div className="guidelines">
                <div className="guidelines-content desktop-only">
                    <img src="https://img.viva88athenae.com//guidelines.png" />
                </div>
            </div>
            <footer className="footer">
                <div
                    dangerouslySetInnerHTML={{
                    __html: dataContentFoorter ? dataContentFoorter : null
                }}></div>
               


                {/* <div className="footer-group">
                    <div className="copyright">
                        <div style={{whiteSpace:"nowrap"}}>© 2015 - 2024 Copyright OKAYIDR.</div>
                        <div>All Rights Reserved.</div>
                    </div>
                    <div className="desktop-only">
                        <div className="d-flex gap-1">
                            <div className="btn-footer" >MOBILE</div>
                            <div className="btn-footer" >WAP</div>
                        </div>
                    </div>
                    <img className="mobile-only" style={{width: "100%"}} src="https://img.viva88athenae.com//guidelines.png" alt=""/>
                </div> */}
            </footer>
            
            
            <div id="mySidenav" className="sidenav desktop-only">
                <div id="nav">
                    <div style={{display: "flex",alignItems:"start"}}>
                        <div style={{height: "246px",width: "64px",background: 'var(--primary-color)',borderRadius:"8px 0px 0px 8px",overflow: "hidden",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                            <img src="https://birutoto.nl/assets/hubungi.png?11"/>
                        </div>
                    </div>
                    <div className="ptsans" style={{fontSize: "1rem",background: "white",borderRadius: "0px 5px 5px 5px",overflow: "hidden",width: "160px"}}>
                        <div id="" style={{padding:"4px"}}>
                            <img src="https://birutoto.nl/assets/24h.png" style={{width: "100%"}}/>
                        </div>

                        <div id="kontak-content" style={{display: "flex", flexDirection: "column", width: "100%", fontSize: ".8rem"}}>
                            <div style={{display: "grid", placeItems: "center", fontWeight: "bold", fontSize: "1rem"}}>MEMBER SERVICE</div>
                            <div style={{padding: "4px", display: "flex", alignItems: "center", gap: "8px", borderBottom: "1px solid #e0e0e0"}}>
                                <img src="https://img.viva88athenae.com/kontak/wa.png" width="20"/><span>{hubungi ? hubungi.wa : null}</span>
                            </div>
                            <div style={{padding: "4px", display: "flex", alignItems: "center", gap: "8px", borderBottom: "1px solid #e0e0e0"}}>
                                <img src="https://img.viva88athenae.com/kontak/line.png" width="20"/><span><span>ID:</span>{hubungi ? hubungi.line : null}</span>
                            </div>
                            <div style={{padding: "4px", display: "flex", alignItems: "center", gap: "8px", borderBottom: "1px solid #e0e0e0"}}>
                                <img src="https://img.viva88athenae.com/kontak/skype.png" width="20"/><span>{hubungi ? hubungi.skype : null}</span>
                            </div>
                            <div style={{padding: "4px", display: "flex", alignItems: "center", gap: "8px", borderBottom: "1px solid #e0e0e0"}}>
                                <img src="https://img.viva88athenae.com/kontak/fb.png" width="20"/><span>{hubungi ? hubungi.fb : null}</span>
                            </div>
                        </div>

                        <div style={{margin: "8px"}}>
                            <input type="button" value="DAFTAR SEKARANG" style={{width: "100%", borderRadius: "5px", fontSize: ".875rem", height: "40px", border: "none", fontWeight: "bold", background: "black", color: "white"}}  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );

}

export default Home;